import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TopPositionCard, TopPositionCardText, PositionCardsContainer, PositionCards, CardWrapper, FitnessTopContainer, FirstPositionImage, OverallTeamPosition, TeamContributionText, SeeAllStyledModal, CustomTitle, TeamCardNew, MemberDataV3, CaptainLogoV2 } from './style';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import { ImageUrl } from '../../utils/constants';
import { commaSeperator, rankingDashboard } from '../../utils/methods';
import { withTranslation } from 'react-i18next';
const  TeamDetailsPopup = React.lazy(() => import('./userTeamDetailsPopup'));
import {connect} from 'react-redux';
import { Modal } from 'react-bootstrap';
import LazyImage from '../common/LazyImage/LazyImage';
import SkeletonLoder from '../common/skeletonLoder';

class BuddiesData extends Component {
  constructor() {
    super();
    this.state = {
      userData: undefined,
      showTeam: false,
      SeeAll: false,
      userIndex: 0,
      teamName: ''
    }
  }

  fallBack = (e) => {
    e.target.src = '/public/images/defaultProfileImg.png'
  }

  leaderboardLimit = () => {
    const { getTopSocialUsers, getTopFitnessUsers, getTopWellnessUsers, getTopUserTotalPoints, companyId, userLimit, pageNumber, activeBuddiesPoints, updateLimit, getCompanyTotalTeamPoints, getTotalTeamUsersPoints } = this.props;
    updateLimit();
    let pageCount;
    pageCount = pageNumber + 1;
    if (activeBuddiesPoints === "1") {
      getTopUserTotalPoints(companyId, pageCount, userLimit);
    }
    else if (activeBuddiesPoints === "2") {
      getTopFitnessUsers(companyId, pageCount, userLimit);
    }
    else if (activeBuddiesPoints === "3") {
      getTopSocialUsers(companyId, pageCount, userLimit);
    }
    else if (activeBuddiesPoints === "4") {
      getTopWellnessUsers(companyId, pageCount, userLimit);
    }
    else if (activeBuddiesPoints === "9") {
      getCompanyTotalTeamPoints(companyId, pageCount, userLimit);
    }
    else if (activeBuddiesPoints === "8") {
      getTotalTeamUsersPoints();
    }
  }

  handleSeeAll = (data, tname) => {
    this.setState({ SeeAll: true, userIndex: data, teamName: tname })
  }

  hideSeeAllModal = () => {
    this.setState({ SeeAll: false })
  }

  SeeAllModal = () => {
    const { SeeAll, userIndex, teamName } = this.state;
    const { companyTeamPoints } = this.props;
    return <SeeAllStyledModal
      show={SeeAll}
      onHide={this.hideSeeAllModal}
      dialogClassName="modal-90w"
    >
      <Modal.Header >
        <CustomTitle>
          <div>
            <span>{this.props.t(teamName)} {this.props.t("Members")}</span>
            <span className="closeImage" onClick={this.hideSeeAllModal}><img src='/public/images/new_close_icon.png' /></span>
          </div>
        </CustomTitle>
      </Modal.Header>
      <Modal.Body>
        {companyTeamPoints[userIndex] && companyTeamPoints[userIndex].team_users.length > 0 ? <TeamCardNew>
          <div className="teamDetails">
            {companyTeamPoints && companyTeamPoints.length > 0 ? companyTeamPoints[userIndex].team_users.map((member, index) => <MemberDataV3 key={index} captain={member.is_captain === 1} border={index === 0}>
              <div>
                <div className='imageDiv'> 
                  {member.is_captain === 1 && <CaptainLogoV2 src='/public/images/captain-band.png' />}
                  <LazyImage src={`${ImageUrl}/${member.profile_image}`} alt="people_icon" /> 
                </div>
              </div>
              <div className='textBox'>
                <div>{index + 1}{rankingDashboard(index)}</div>
                <h1>{member.fullname} 
                  {member.is_captain === 1 && 
                  <span className='max'> &nbsp;({this.props.t("Captain")})</span>} 
                </h1>
              </div>
              <div className='showpoint'>
                <div>{member.total_points}</div>
                <span>{this.props.t("Total Pts")}</span>
              </div>
            </MemberDataV3>) : null}
          </div>
        </TeamCardNew>: <h3 style={{color:'#0D4270'}}>{this.props.t("No members added to this team")}</h3>}
      </Modal.Body>
    </SeeAllStyledModal>
  }


  buddiesAllPositionData = (buddiesAllTypesData) => {
    const { activeBuddiesPoints, t } = this.props;
    return (
      activeBuddiesPoints === "1" ?
        (buddiesAllTypesData.map((totalData, index) =>
          index > 2 && <PositionCards key={index}>
            <div>
              <LazyImage onError={this.fallBack} src={`${ImageUrl}/${totalData.profile_image}`} alt="Profile" />
              <div>
                <div>{ t(index + 1 + rankingDashboard(index))}</div>
                <div>{totalData.name}</div>
              </div>
            </div>

            <div>
              <div>{totalData.total_points ? commaSeperator(totalData.total_points) : 0}</div>&nbsp;
              <div>{t("Total Points")}</div>
            </div>
          </PositionCards>

        )) :
        activeBuddiesPoints === "2" ?
          (buddiesAllTypesData.map((totalData, index) =>
            index > 2 && <PositionCards key={index}>
              <div>
                <LazyImage onError={this.fallBack} src={`${ImageUrl}/${totalData.profile_image}`} alt="Profile" />
                <div>
                  <div>{t(index + 1 + rankingDashboard(index))}</div>
                  <div>{totalData.name}</div>
                </div>
              </div>

              <div>
                <div>{totalData.fitness_points ? commaSeperator(totalData.fitness_points) : 0}</div>&nbsp;
                <div>{t("Fitness Points")}</div>
              </div>
            </PositionCards>

          )) :
          activeBuddiesPoints === "3" ?
            (buddiesAllTypesData.map((totalData, index) =>
              index > 2 && <PositionCards key={index}>
                <div>
                  <LazyImage onError={this.fallBack} src={`${ImageUrl}/${totalData.profile_image}`} alt="Profile" />
                  <div>
                    <div>{t(index + 1 + rankingDashboard(index))}</div>
                    <div>{totalData.name}</div>
                  </div>
                </div>

                <div>
                  <div>{totalData.social_points ? commaSeperator(totalData.social_points) : 0}</div>&nbsp;
                  <div>{t("Social Points")}</div>
                </div>
              </PositionCards>

            )) :
            activeBuddiesPoints === "4" ?
              (buddiesAllTypesData.map((totalData, index) =>
                index > 2 && <PositionCards key={index}>
                  <div>
                    <LazyImage onError={this.fallBack} src={`${ImageUrl}/${totalData.profile_image}`} alt="Profile" />
                    <div>
                      <div>{t(index + 1 + rankingDashboard(index))}</div>
                      <div>{totalData.name}</div>
                    </div>
                  </div>

                  <div>
                    <div>{totalData.wellness_points ? commaSeperator(totalData.wellness_points) : 0}</div>&nbsp;
                    <div>{t("Wellness Points")}</div>
                  </div>
                </PositionCards>)) :
              activeBuddiesPoints === "9" ?
                (buddiesAllTypesData && buddiesAllTypesData.length && buddiesAllTypesData.map((totalData, index) =>
                  index > 2 && <div key={index}> <PositionCards key={index}>
                    <div>
                      <LazyImage fallBack={this.fallBack} src={`${ImageUrl}/${totalData.logo}`} alt="Profile" />
                      <div>
                        <div>{t(index + 1 + rankingDashboard(index))}</div>
                        <div>{totalData.name ? this.props.t(totalData.name) : `Team ${index + 1}`}</div>
                      </div>
                    </div>

                    {totalData.team_users.length > 0 ? <div>
                      <div>{totalData.total_team_points ? commaSeperator(totalData.total_team_points) : 0}</div>&nbsp;
                      <div>{t("Total Points")}</div>
                      <button onClick={() => this.handleSeeAll(index, totalData.name)}>
                        {this.state.userData == index ?
                          <LazyImage src={ImageUrl + '/images/dropupArrow.png'}></LazyImage>
                          :
                          <LazyImage src={ImageUrl +'/images/dropdownArrow.png'} />
                        }
                      </button>
                    </div> : <div style={{color: '#0D4270', marginRight: '10px'}}>{this.props.t("No Members Added")}</div>}
                  </PositionCards>
                  </div>)) : null
    )
  }

  onScroll = (e) => {
    const bottom = Math.round(e.target.scrollHeight - parseInt(e.target.scrollTop,10)) === Math.round(e.target.clientHeight);
    if (bottom) {
      this.leaderboardLimit()
    }
  };

  showTeamDetails = () => {
    this.setState({
      showTeam: !this.state.showTeam
    })
  };

  render() {
    const { socialUserData, fitnessUserData, wellnessUserData, topUsersTotalPoints, activeBuddiesPoints, t, companyTeamPoints, teamUsersPoints, teamIconsData } = this.props;
    if (activeBuddiesPoints === "1" && isEmpty(topUsersTotalPoints)) {
      return <div style={{width:'835px'}}>
        <SkeletonLoder width={"100%"} height={"355px"} style={{borderRadius:'16px'}}/>
        <SkeletonLoder width={"100%"} height={"80px"} style={{margin:'15px 0px 10px 0px'}}/>
        <SkeletonLoder width={"100%"} height={"80px"}/>
      </div>
    } else if (activeBuddiesPoints === "2" && isEmpty(fitnessUserData)) {
      return <div style={{width:'835px'}}>
        <SkeletonLoder width={"100%"} height={"355px"} style={{borderRadius:'16px'}}/>
        <SkeletonLoder width={"100%"} height={"80px"} style={{margin:'15px 0px 10px 0px'}}/>
        <SkeletonLoder width={"100%"} height={"80px"}/>
      </div>
    } else if (activeBuddiesPoints === "3" && isEmpty(socialUserData)) {
      return <div style={{width:'835px'}}>
        <SkeletonLoder width={"100%"} height={"355px"} style={{borderRadius:'16px'}}/>
        <SkeletonLoder width={"100%"} height={"80px"} style={{margin:'15px 0px 10px 0px'}}/>
        <SkeletonLoder width={"100%"} height={"80px"}/>
      </div>
    } else if (activeBuddiesPoints === "4" && isEmpty(wellnessUserData)) {
      return <div style={{width:'835px'}}>
        <SkeletonLoder width={"100%"} height={"355px"} style={{borderRadius:'16px'}}/>
        <SkeletonLoder width={"100%"} height={"80px"} style={{margin:'15px 0px 10px 0px'}}/>
        <SkeletonLoder width={"100%"} height={"80px"}/>
      </div>
    } else if (activeBuddiesPoints === "9" && isEmpty(companyTeamPoints)) {
      return <div style={{width:'835px'}}>
        <SkeletonLoder width={"100%"} height={"355px"} style={{borderRadius:'16px'}}/>
        <SkeletonLoder width={"100%"} height={"80px"} style={{margin:'15px 0px 10px 0px'}}/>
        <SkeletonLoder width={"100%"} height={"80px"}/>
      </div>
    } else if (activeBuddiesPoints === "8" && isEmpty(teamUsersPoints)) {
      return <div style={{width:'835px'}}>
        <SkeletonLoder width={"100%"} height={"355px"} style={{borderRadius:'16px'}}/>
        <SkeletonLoder width={"100%"} height={"80px"} style={{margin:'15px 0px 10px 0px'}}/>
        <SkeletonLoder width={"100%"} height={"80px"}/>
      </div>
    }
        
    const buddiesAllTypeData = activeBuddiesPoints === "1" && topUsersTotalPoints && topUsersTotalPoints.length ? topUsersTotalPoints : activeBuddiesPoints === "2" && fitnessUserData && fitnessUserData.length ? fitnessUserData : activeBuddiesPoints === "3" && socialUserData && socialUserData.length ? socialUserData : activeBuddiesPoints === "4" && wellnessUserData && wellnessUserData.length ? wellnessUserData : activeBuddiesPoints === "9" && companyTeamPoints && companyTeamPoints.length ? companyTeamPoints : null;
    return (
      <React.Fragment>
        {
          <CardWrapper onScroll={this.onScroll}>
            {activeBuddiesPoints !== "8" ?
              <TopPositionCard margin="1">
                <TopPositionCardText margin="1">
                  <div>{activeBuddiesPoints === "1" ? t("Top Buddies Total Points") : activeBuddiesPoints === "2" ? t("Top Buddies Fitness Points") : activeBuddiesPoints === "3" ? t("Top Buddies Social Points") : activeBuddiesPoints === "4" ? t("Top Buddies Wellness Points") : t("Top Teams")}</div>
                </TopPositionCardText>
                <FitnessTopContainer>
                  <FirstPositionImage>
                    <img src={'/public/images/behaviorChallenges/winner.png'} alt={'winner'} />
                  </FirstPositionImage>
                  {!isUndefined(buddiesAllTypeData[0]) ? <div><PositionCards color="1" background="rgba(246, 180, 121, 1)">
                    <div>
                      {
                        <LazyImage fallbackImage="/public/images/defaultProfileImg.png" src={`${ImageUrl}/${buddiesAllTypeData && buddiesAllTypeData.length && buddiesAllTypeData[0].profile_image ? buddiesAllTypeData[0].profile_image : (buddiesAllTypeData[0].logo ? buddiesAllTypeData[0].logo : "")}`} alt="Profile" />
                      }
                      <div>
                        <div>{t("1st")}</div>
                        <div>
                          {buddiesAllTypeData[0]?.name ? buddiesAllTypeData[0]?.name : "Team 1"}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        {activeBuddiesPoints === "1" ? buddiesAllTypeData[0].total_points ? commaSeperator(buddiesAllTypeData[0].total_points) : 0 : activeBuddiesPoints === "2" ? buddiesAllTypeData[0].fitness_points ? commaSeperator(buddiesAllTypeData[0].fitness_points) : 0 : activeBuddiesPoints === "3" ? buddiesAllTypeData[0].social_points ? commaSeperator(buddiesAllTypeData[0].social_points) : 0 : activeBuddiesPoints === "4" ? buddiesAllTypeData[0].wellness_points ? commaSeperator(buddiesAllTypeData[0].wellness_points) : 0 : buddiesAllTypeData[0].total_team_points ? commaSeperator(buddiesAllTypeData[0].total_team_points) : 0}
                      </div>&nbsp;
                      <div>{activeBuddiesPoints === "1" ? t("Total Points") : activeBuddiesPoints === "2" ? t("Fitness Points") : activeBuddiesPoints === "3" ? t("Social Points") : activeBuddiesPoints === "4" ? t("Wellness Points") : t("Total Points")}</div>
                      {activeBuddiesPoints === '9' && <button onClick={() => {this.handleSeeAll(0, buddiesAllTypeData[0]?.name)}
                      }>
                        {this.state.userData === 0 ?
                          <LazyImage src={ImageUrl +'/images/dropupArrow.png'}></LazyImage>
                          :
                          <LazyImage src={ImageUrl +'/images/dropdownArrow.png'}></LazyImage>
                        }
                      </button>}
                    </div> 
                  </PositionCards>
                  </div> : null}
                  {!isUndefined(buddiesAllTypeData[1]) ? <div><PositionCards color="1" background="rgb(159, 201, 137)">
                    <div>
                      {
                        <LazyImage fallbackImage="/public/images/defaultProfileImg.png" src={`${ImageUrl}/${buddiesAllTypeData && buddiesAllTypeData.length && buddiesAllTypeData[1].profile_image ? buddiesAllTypeData[1].profile_image : (buddiesAllTypeData[1].logo ? buddiesAllTypeData[1].logo : "")}`} alt="Profile" />
                      }
                      <div>
                        <div>{t("2nd")}</div>
                        <div>
                          {buddiesAllTypeData[1].name ? buddiesAllTypeData[1].name : "Team 2"}
                        </div>
                      </div>
                    </div>

                    <div>
                      <div>
                        {activeBuddiesPoints === "1" ? buddiesAllTypeData[1].total_points ? commaSeperator(buddiesAllTypeData[1].total_points) : 0 : activeBuddiesPoints === "2" ? buddiesAllTypeData[1].fitness_points ? commaSeperator(buddiesAllTypeData[1].fitness_points) : 0 : activeBuddiesPoints === "3" ? buddiesAllTypeData[1].social_points ? commaSeperator(buddiesAllTypeData[1].social_points) : 0 : activeBuddiesPoints === "4" ? buddiesAllTypeData[1].wellness_points ? commaSeperator(buddiesAllTypeData[1].wellness_points) : 0 : buddiesAllTypeData[1].total_team_points ? commaSeperator(buddiesAllTypeData[1].total_team_points) : 0}
                      </div>&nbsp;
                      <div>{activeBuddiesPoints === "1" ? t("Total Points") : activeBuddiesPoints === "2" ? t("Fitness Points") : activeBuddiesPoints === "3" ? t("Social Points") : activeBuddiesPoints === "4" ? t("Wellness Points") : t("Total Points")}</div>
                      {activeBuddiesPoints === '9' && <button onClick={() => {this.handleSeeAll(1, buddiesAllTypeData[1].name)}
                      }>
                        {this.state.userData === 1 ?
                          <LazyImage src={ImageUrl +'/images/dropupArrow.png'}></LazyImage>
                          :
                          <LazyImage src={ImageUrl +'/images/dropdownArrow.png'}></LazyImage>
                        }
                      </button>}
                    </div>
                  </PositionCards>
                  </div> : null}
                  {!isUndefined(buddiesAllTypeData[2]) ? <div><PositionCards color="1" background="rgba(253, 113, 117, 1)">
                    <div>
                      {
                        <LazyImage fallbackImage="/public/images/defaultProfileImg.png" src={`${ImageUrl}/${buddiesAllTypeData && buddiesAllTypeData.length && buddiesAllTypeData[2].profile_image ? buddiesAllTypeData[2].profile_image : (buddiesAllTypeData[2].logo ? buddiesAllTypeData[2].logo : "")}`} alt="Profile" />
                      }
                      <div>
                        <div>{t("3rd")}</div>
                        <div>
                          {buddiesAllTypeData[2].name ? buddiesAllTypeData[2].name : "Team 3"}
                        </div>
                      </div>
                    </div>

                    <div>
                      <div>
                        {activeBuddiesPoints === "1" ? buddiesAllTypeData[2].total_points ? commaSeperator(buddiesAllTypeData[2].total_points) : 0 : activeBuddiesPoints === "2" ? buddiesAllTypeData[2].fitness_points ? commaSeperator(buddiesAllTypeData[2].fitness_points) : 0 : activeBuddiesPoints === "3" ? buddiesAllTypeData[2].social_points ? commaSeperator(buddiesAllTypeData[2].social_points) : 0 : activeBuddiesPoints === "4" ? buddiesAllTypeData[2].wellness_points ? commaSeperator(buddiesAllTypeData[2].wellness_points) : 0 : buddiesAllTypeData[2].total_team_points ? commaSeperator(buddiesAllTypeData[2].total_team_points) : 0}
                      </div>&nbsp;
                      <div>{activeBuddiesPoints === "1" ? t("Total Points") : activeBuddiesPoints === "2" ? t("Fitness Points") : activeBuddiesPoints === "3" ? t("Social Points") : activeBuddiesPoints === "4" ? t("Wellness Points") : t("Total Points")}</div>
                      {activeBuddiesPoints === '9' && <button onClick={() => {this.handleSeeAll(2, buddiesAllTypeData[2].name)}
                      }>
                        {this.state.userData === 2 ?
                          <LazyImage src={ImageUrl +'/images/dropupArrow.png'}></LazyImage>
                          :
                          <LazyImage src={ImageUrl +'/images/dropdownArrow.png'}></LazyImage>
                        }
                      </button>}
                    </div>
                  </PositionCards>
                  </div> : null}
                </FitnessTopContainer>
              </TopPositionCard>
              :
              teamUsersPoints[0] ? (teamUsersPoints && teamUsersPoints.length > 0 && teamUsersPoints.map((data, index) => <div key={index}> <OverallTeamPosition>
                <div className='upper-text'>
                  <div className='heading'>Overall Team Position</div>
                  <div className='position'>{data.user_rank}{rankingDashboard(data.user_rank - 1)}</div>
                </div>
                <div className='lower-data'>
                  <div className='edit-button'>
                    <img src={data.logo ? `${ImageUrl}/${data.logo}` : '/public/images/team-iconn.png'}></img>
                    {data.is_user_captain === 1 && <button onClick={this.showTeamDetails}>
                      <img src='/public/images/editiconn.png'></img>
                      <div className='editText'>Edit</div>
                    </button>}
                  </div>
                  <div className='teamdata-rows'>
                    <div className='row1'>
                      <div className='team-head'>Team Name</div>
                      <div className='team-name'>{data.name}</div>
                    </div>
                    <div className='row1' style={{ paddingTop: '10px' }}>
                      <div className='team-head'>Slogan</div>
                      <div className='team-name2'>{data.slogan}</div>
                    </div>
                    <div className='row1' style={{ paddingTop: '10px', border: '0' }}>
                      <div className='team-head'>Team Contribution</div>
                      <div style={{display: 'flex'}}>
                        <div className='team-name3' style={{marginRight: '3px'}}>{data.total_team_points}</div>
                        <div style={{color: '#0D4270'}}>Pts</div>
                      </div>
                    </div>
                  </div>
                </div>
              </OverallTeamPosition>
              <TeamContributionText>
                <div>My Team Contribution</div>
              </TeamContributionText>
              {teamUsersPoints && teamUsersPoints.length && teamUsersPoints[0].team_users && teamUsersPoints[0].team_users.length && teamUsersPoints[0].team_users.map((data, index) => <PositionCards key={index} style={{ marginTop: '10px' }} background="#fff">
                <div>
                  <div>
                    {data.is_captain === 1 && <img className='captainband' src='/public/images/captain-band.png'/>}
                    {
                      <LazyImage onError={this.fallBack} src={`${ImageUrl}/${(data.profile_image ? data.profile_image : "")}`} alt="Profile" />
                    }
                  </div>
                  <div>
                    <div>{index + 1}{rankingDashboard(index)}</div>
                    <div display>
                      {data.fullname}
                      {data.is_captain === 1 && <div>(Captain)</div>}
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    {data.total_points}
                  </div>&nbsp;
                  <div>{("Total Points")}</div>
                </div>
              </PositionCards>)}</div>)): <h1 style={{textAlign: 'center', color: '#0D4270', fontFamily: 'Rubik-Medium'}}>You are not team member of any team.</h1>
            }
            <PositionCardsContainer >
              {this.buddiesAllPositionData(buddiesAllTypeData)}
            </PositionCardsContainer>
          </CardWrapper>
        }
        {
          this.state.showTeam &&
          <TeamDetailsPopup show={this.state.showTeam} hideModal={this.showTeamDetails} teamUsersPoints={teamUsersPoints} teamIconsData={teamIconsData[0]} />
        }
        {this.SeeAllModal()}
      </React.Fragment>
    )
  }
}

BuddiesData.propTypes = {
  history: PropTypes.object.isRequired,
  fitnessLeaderboard: PropTypes.object,
  wellnessUserData: PropTypes.object,
  fitnessUserData: PropTypes.object,
  socialUserData: PropTypes.object,
  topUsersTotalPoints: PropTypes.object,
  companyTeamPoints: PropTypes.object,
  teamUsersPoints: PropTypes.object,
  teamIconsData: PropTypes.array,
  activeBuddiesPoints: PropTypes.string,
  leaderboardLimit: PropTypes.func,
  pageNumber: PropTypes.number,
  getTopSocialUsers: PropTypes.func,
  getTopFitnessUsers: PropTypes.func,
  getTopWellnessUsers: PropTypes.func,
  getTopUserTotalPoints: PropTypes.func,
  getCompanyTotalTeamPoints: PropTypes.func,
  getTotalTeamUsersPoints: PropTypes.func,
  companyId: PropTypes.number,
  userLimit: PropTypes.number,
  updateLimit: PropTypes.func,
  t: PropTypes.func,
};

const mapStateToProps = (state) => ({
  teamUsersPoints: state.companyDashboard.teamUsersPoints,
})

export default connect(mapStateToProps, null)(withTranslation()(BuddiesData));
